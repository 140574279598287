.CrmLayout {
    display: flex;
    height: 100%;
  
    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
    }
  }