@import 'src/assets/styles/fonts.scss';

.SideBar__bottom {
  margin-bottom: 4.8rem;
  font-family: 'Baguede';
  width: 100%;

  &__setting {
    display: flex;
    align-items: center;

    font-size: 1.8rem;
    cursor: pointer;

    &__icon {
      margin-right: 1.9rem;
    }
  }
}