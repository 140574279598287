@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.StreamPassesBlock {
  background-color: #EDEDED;
  margin: 3rem 0;
  padding: 2rem 0;
  &__header {
    // background: $color-light-gray;
    padding: 1.6rem 4.2rem;
    font-family: 'BaguedeFree-Regular', serif;
    opacity: 0.9;
    font-size: 1.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    padding: 4.6rem 4.2rem;
  }
}


@media (max-width: 1550px) {
  .SteamPasses__forms {
    display: flex;
    flex-direction: column;
    gap: 5rem !important;
  }
}