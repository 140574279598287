@import "src/assets/styles/fonts.scss";
@import "src/assets/styles/variables.scss";



.accordionWrapBox{
  background-color: #F5F4F4;
  height: fit-content;
  padding: 15px 25px;
}


.section {
  width: 100%;
  max-width: 100%;
  margin: 10px 0 40px 0;
}

.section_one {
  width: 60%;
  max-width: 100%;
}

.container {
  width: 100%;
}

#editor-container {
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
}

.segment_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .segment_top_right {
    display: flex;
    align-items: center;


    .recipients {
      background: #E7E6EB;
      padding: 2rem 3rem;
      margin-left: auto;
      min-width: 15rem;
    }
  }
}

.segment_bottom{
    .time-label{
        margin-bottom: 8px;
        display: block;
       font-size: 1.2rem;
    }
}
