.LiveStreamLayout {
  display: flex;
  margin-top: 2rem;
  min-height: 100%;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}