@import '/src/assets/styles/fonts.scss';

.wrapperInputBlock {
  &__label {
    display: block;
    font-size: 1.2rem;
    font-family: 'Baguede';
    white-space: nowrap;
  }

  
}

