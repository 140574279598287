@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.HeadNavBarItemWrapper {
  .HeadNavBarItem {
    font-family: 'Baguede';
    padding: 2rem;
    font-size: 1.8rem;
  }

  color: #181818;
}


.HeadNavBarItemWrapper {
  .active {
    color: $color-black;
    background: $color-light-gray;
  }
}

.disable-link {
  pointer-events: none;
}
