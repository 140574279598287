@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.Page {
  position: relative;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;


  &__content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    &__title {
      font-family: "baguede";
      color: $color-black-gray;
      font-size: 2.5rem;
      font-weight: 800;
    }
  }
}