* {
  font-family: "Baguede" !important;
}

html {
  font-size: 10px;
  //height: 100%;
  // font-family: "Baguede";
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: "Baguede" !important;
}

//body > div {
//  display: none
//}
//
//body > div#root, body > div#account-menu {
//  display: block
//}

#root {
  //height: 100%;
  font-family: "Baguede" !important;
}

h1 {
  font-size: 3.6rem;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 2.2rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  font-size: 1.8rem;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 24px;
  /* Set your desired font size here */
  /* Add any other custom styles as needed */
}

.css-i4bv87-MuiSvgIcon-root {
  width: 2rem !important;
  /* Adjust the width as needed */
  height: 2rem !important;
  /* Adjust the height as needed */
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 1.5rem;
  font-weight: 200 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 1.6rem !important;
}

.css-1mo6sn8-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background: none !important;
  box-shadow: none !important;
}

.css-11yflir-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background: none !important;
  box-shadow: none !important;
}

.css-1kietdx-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
  .MuiInputBase-input {
  border: none !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none !important;
}


// .public-DraftStyleDefault-block {
//   margin: 0px !important;
// }

.wrapperClassName {
  width: 100%;
  // height: 500px;
  overflow: hidden;
}


.editorClassName{
  height: 600px;
  width: 100%;
  border: 10px solid #eee;
  margin-bottom: 20px;
}

.toolbarClassName{
  // width: 100%;
  background-color: #000;
  // display: flex;
  // flex-wrap: wrap;
  padding: 20px 60px 20px 0px;
  width: 100%;
}
.rdw-image-modal{
  left: -300% !important;
}

.DraftEditor-root {
  font-size: 24px;
  padding: 20px;
  figure {
      margin-block-start: .5em;
      margin-block-end: .5em;
      margin-inline-start: 0;
      margin-inline-end: 0;
  }
img {
    width: 600px !important;
    height: 400px !important;
    margin: 0 auto;
    object-fit: cover;
}

}

