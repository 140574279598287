@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';


.tag-wrap{
    width: 100%;
    margin-top: 15px;
   input{
    height: 1.9575em;
    width: 100%;
    max-width: 97%;
    border: 1px solid #999898;
    background-color: transparent;
    padding: 16.5px 14px;
    border-radius: 3px;
    // outline: none;
   }
}

.tag-list{
    margin-top: 5px;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}