@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.StreamInformationForm {
  width: 100%;
  &__title {
    padding-bottom: 2.9rem;
    font-size: 1.8rem;
    font-family: 'Baguede';
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}