@import "src/assets/styles/variables.scss";
@import "src/assets/styles/fonts.scss";

.SteamPasses {
  background: #f5f4f4;
  font-family: "Baguede";
  width: 100%;

  &__livetsream__forms {
    display: flex;
    gap: 4rem;
    width: 100%;
    justify-content: space-between;
     @media screen and (max-width: 1251px){
      flex-direction: column-reverse;

    }
  }
}

.section__subtitle {
  font-size: 15px;
  font-family: "Baguede";
  color: #000000;
  margin-top: -10px;
  opacity: 0.8;
  letter-spacing: -0.48px;
}
