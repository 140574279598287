@font-face {
  font-family: 'MADE Outer Sans';
  src: url('../fonts/madeOuterSans/MADE_Outer_Sans_Regular_PERSONAL_USE.otf');
}

@font-face {
  font-family: 'MADE Outer Sans Light';
  src: url('../fonts/madeOuterSans/MADE Outer Sans Light PERSONAL USE.otf');
}

@font-face {
  font-family: 'MADE Outer Sans Thin';
  src: url('../fonts/madeOuterSans/MADE Outer Sans Thin PERSONAL USE.otf');
}

@font-face {
  font-family: 'MADE Outer Sans Light';
  src: url('../fonts/madeOuterSans/MADE Outer Sans Light PERSONAL USE.otf');
}

@font-face {
    font-family: 'BwModelica-Bold';
    src: url('../fonts/bwModelica/BwModelica-Bold.otf');
}

@font-face {
    font-family: 'BwModelica-Regular';
    src: url('../fonts/bwModelica/BwModelicaSS02-Regular.otf');
}


@font-face {
  font-family: 'Baguede';
  src: url('../fonts/baguede/Baguede-Regular.ttf');
}

@font-face {
  font-family: 'Baguede-Italic';
  src: url('../fonts/baguede/BaguedeFree-Italic.otf');
}


