@import 'src/assets/styles/fonts.scss';
@import 'src/assets/styles/variables.scss';

.BlockWithToggle {
  width: 100%;

  &__header {
    background: $color-light-gray;
    padding: 1.6rem 4.2rem;
    font-family: "Baugede";
    opacity: 0.9;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    //padding: 4.6rem 4.2rem;
    gap: 14rem;
    justify-content: space-between;
    display: flex;
  }
}