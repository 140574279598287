.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.wrapperImage {
  //flex: 0 0 77rem;
  //position: relative;
  //max-width: 77rem;

  //img {
  //  position: absolute;
  //  width: 100%;
  //}
}

@media screen and (max-width: 1600px) {
  .BoxWrapper {
    display: flex;
    flex-direction: column;
  }

  .wrapperImage {
    //flex: 0 0 36rem;
    //position: relative;
    //width: 100%;
    // max-width: 77rem;
    //max-width: 100%;

    img {
      //height: 100%;
    }
  }
}
