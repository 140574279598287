@import "/src/assets/styles/fonts.scss";

.Autocomplete {
  &__renderInput {
    position: relative;
    label {
      display: block;
      font-size: 1.2rem;
      padding-bottom: 0.8rem;
      font-family: "Baguede";
    }

    .rotateUp {
      transform: rotate(-180deg);
      transform-origin: center;
      transition: transform 0.3s ease;
    }

    .dropIcon {
      cursor: pointer;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      transition: transform 0.3s ease;
    }

    /* Style the icon container */
    .icon-container {
      position: absolute;
      top: 65%;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 7px;
      transform: translateY(-50%);
    }
  }
}
