@import '/src/assets/styles/variables.scss';

.StreamPurchaseInformationForm {
  width: 100%;
  &__title {
    padding-bottom: 2.9rem;
    font-size: 1.8rem;
    font-family: 'Baguede';
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    &__checkbox--group--label{
      margin-top: 20px;
    }

    &__input--group {
      display: flex;
      gap: 1rem;
      padding: 2rem 0;
    }

    &__checkbox--group {
      &--label {
        font-family: 'Baguede';
        color: $color-black-gray;
        display: block;
        opacity: .9;
        font-size: 1.6rem;
      }

      display: flex;
      align-items: center;
      gap: 2rem;
      padding: 2rem 0;
    }
  }
}