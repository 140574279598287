@import "src/assets/styles/fonts.scss";
@import "src/assets/styles/variables.scss";

.TransactionInformationListItem {
  margin: 10px 0;
  &__item {
    padding: 10px 0 10px 40px;
    &--title {
      color: $color-orange;
      font-size: 1.8rem;
      font-family: "Baguede";
      padding-bottom: 2.3rem;
      display: block;
    }

    &--content {
      font-size: 1.6rem;
      font-family: "Baguede";
      padding-bottom: 1.1rem;
      opacity: 0.87;
    }

    &--success {
      span {
        font-size: 1.8rem;
        font-family: "Baguede";
        padding-bottom: 1.3rem;
        color: #aadd6a;
      }
    }

    &--failed {
      span {
        font-size: 1.8rem;
        font-family: "Baguede";
        padding-bottom: 1.3rem;
        color: red;
      }
    }

    &--Pending {
      span {
        font-size: 1.8rem;
        font-family: "Baguede";
        padding-bottom: 1.3rem;
      }
    }
  }
}

.TransactionInformationListItem {
  &__item__center {
    background-color: #e7e6eb;
    padding: 20px 0 20px 40px;
    margin: 10px 0;
    &--title {
      color: $color-orange;
      font-size: 1.8rem;
      font-family: "Baguede";
      padding-bottom: 2.3rem;
      display: block;
    }

    &--content {
      font-size: 1.7rem;
      font-family: 'Baguede';
      padding-bottom: 1.5rem;
      font-weight: 100;
      opacity: 0.87;
    }
  }
}
