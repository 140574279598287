@import "src/assets/styles/variables.scss";
@import "src/assets/styles/fonts";

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.ButtonBox {
  cursor: pointer;
  max-height: 5.6rem;
  display: inline-flex;

  .Button {
    cursor: pointer;
    position: relative;
    height: 100%;
    line-height: 1;
    font-family: "Baguede";
    font-size: 2rem;
    background: $color-black;
    color: $color-white;
    border: none;
    padding: 1.5rem 2rem;
    white-space: nowrap;

    &__modal {
      background: $color-orange;
    }

    &__disabled {
      background-color: #ededed;
      color: #000000;
      cursor: not-allowed;
      span {
        background-color: #ededed;
      }
    }

    &__loading {
      &__text {
        visibility: hidden;
        opacity: 0;
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-top-color: #ffffff;
        border-radius: 50%;
        animation: button-loading-spinner 1s ease infinite;
      }
    }
  }
}

.modal {
  button {
    background: $color-orange;
  }
}
