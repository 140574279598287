@import "src/assets/styles/variables.scss";

.FormWrapper {
  background: $color-gray;
  display: block;
  padding: 2rem;
  position: relative;
  flex: 1 1 auto;
  font-family: "Baguede";
}

.EditorFormWrap{
    display: block;
    position: relative;
    flex: 1 1 auto;
    font-family: "Baguede";
}


