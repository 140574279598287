@import "src/assets/styles/variables.scss";
@import "src/assets/styles/fonts.scss";

.TransactionInformation__wrapper {
  display: flex;
  background: $color-gray;
  position: fixed;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 101;
  max-width: 40rem;
  width: 100%;
  flex-direction: column;

  .TransactionInformation {
    overflow-y: scroll;
    padding-top: 30px;
    padding-bottom: 40px;
    &__top {
      font-size: 2rem;
      font-family: "Baguede";
      font-weight: 500;
      display: flex;
      padding: 10px 20px 10px 20px;
      justify-content: space-between;

      button {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}
