@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.UploadEventPrint {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 5rem;

  button {
    background: $color-black;
    color: $color-white;
    border: 0.5px solid #000000;
    padding: 1rem 1.6rem;
    font-family: 'Baguede';
    font-size: 1.6rem;
    cursor: pointer;
    min-width: 13rem;
  }

  &__label {
    font-family: 'Baguede';
    color: #000000;
      opacity: 1;
  }

  &__subText {
    font-family: 'Baguede';
    white-space: nowrap;
    letter-spacing: -0.26px;
      color: #000000;
      opacity: 0.63;
  }
}

@media screen and (max-width: 1200px) {
  .UploadEventPrint {
    flex-direction: column;
    gap: 2rem;
    align-items: start;
  }
}

.dropImageWrapper {
  width: 30px;
  height: 30px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}