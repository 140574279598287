.bio-section {
  margin-bottom: 4rem;
  h3 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .bio {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}

.contact-section {
  margin-bottom: 4rem;
  h3 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .contacts {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.social-section {
  margin-bottom: 3rem;
  h3 {
    font-size: 2rem;
  }
}

.socials {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
