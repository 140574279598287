@import "src/assets/styles/variables.scss";

.wrapperSelectBlock {
  &__label {
    display: block;
    font-size: 1.2rem;
    padding-bottom: 0 !important;
    font-family: "Baguede";
  }

}

.select-wrap {
  position: relative;


 

  .rotateUp {
    transform: rotate(-180deg);
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  .dropIcon {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    z-index: 1;
  }

  /* Style the icon container */
  .icon-container {
    position: absolute;
    right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 0.8em);
  }


}




