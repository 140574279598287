@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.SprayMoneyBalance {
  background: $color-light-gray;
  padding: 1.7rem;

  &__top {
    span {
      font-family: 'Baguede';
      font-size: 1.8rem;
    }
  }

  &__content {
    padding: 1.9rem 0;
    span {
      font-family: 'Baguede';
      font-size: 2.4rem;
    }
  }

  &__action {
    text-align: end;
    button {
      border: none;
      background: none;
      color: $color-orange;
      text-decoration: underline;
      font-family: 'Baguede';
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

}