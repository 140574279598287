@import 'src/assets/styles/fonts.scss';
@import 'src/assets/styles/variables.scss';

.SprayMoney {
  background: $color-gray;
  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10rem;
    padding: 4.5rem;
  }

  &__forms {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}