@import 'src/assets/styles/variables.scss';

@media screen and (min-width: 1200px) {
  .ArtistBlock {
    max-width: 300px;
  }
}
.ArtistBlock {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__event--info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    font-size: 2rem;
    font-family: 'Baguede';
  }

  &__user__data {
    display: flex;
    //margin-top: 2rem;
  }

  .ArtistBlock__event__data {
    display: flex;
    font-size: 2rem;
    gap: 5rem;
    padding: 3rem 0;
    &__stream--passes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      span {
        font-family: 'Baguede';
        display: block;
      }
    }

    &__events {
      display: flex;
      flex-direction: column;

      span {
        display: block;
      }
    }
  }
}

