@import 'src/assets/styles/fonts.scss';
@import 'src/assets/styles/variables.scss';

.AddFieldButton__wrapper {
  .AddFieldButton {
    background: none;
    border: none;
    align-items: center;
    display: flex;
    gap: 1.6rem;
    opacity: 0.9;
    color: $color-black-gray;
    font-family: 'Baguede';
  }
}