.toggler {
  border: 1px solid #292929;
  min-width: 90px;
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 2px;
  margin-right: 80px;

  .toggler_text {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .active {
    background-color: #000001;
    color: #ffffff;
  }
}
