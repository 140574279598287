@import 'src/assets/styles/variables.scss';


.UserInformationBlock {
  display: inline-flex;
  flex-direction: column;
  gap: 2rem;

  &__user {
    border-bottom: 1px dashed $color-asphalt;
    font-family: 'Baguede';
  }

  &__user__data {
    display: flex;
    margin-bottom: 2rem;
    gap: 2rem;

    align-items: flex-end;

    &__register--data {
      font-family: 'BaguedeFree-Regular', serif;
      font-size: 2rem;
      justify-content: space-between;
      display: flex;
      flex: 1 1 auto;
    }

    span {
      font-size: 2rem;
    }
  }

  .UserInformationBlock__event__data {
    display: flex;
    font-size: 2rem;
    gap: 2rem;

    &__stream--passes {
      display: flex;
      flex-direction: column;
      font-family: 'Baguede';
      gap: 1rem;
      span {
        display: block;
      }
    }

    &__events {
      display: flex;
      flex-direction: column;
      font-family: 'Baguede';
      gap: 1rem;
      span {
        display: block;
      }
    }
  }
}