.Management__password {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5.6rem;


  &__password {
    align-items: flex-end;
  }
}

.label {
  display: block;
  font-size: 1.2rem;
  margin-left: .8rem;
  font-family: 'Baguede';
}
