@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.Modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__content {
    background: $color-white;
    margin: 0 auto;
    min-width: 68rem;
    padding: 0 6.8rem 4rem;

    &__title {
      text-align: center;
      position: relative;
      padding-top: 4.5rem;

      &__close--button {
        background: none;
        right: -5rem;
        top: 2rem;
        border: none;
        position: absolute;
        cursor: pointer;
      }
    }

    &__action {
      text-align: center;
    }

    &__main {
      padding: 3.7rem 0 0 0;
    }
  }
}

.blackout {
  z-index: -1;
  position: absolute;
  background: black;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .85;
}