@import "src/assets/styles/variables.scss";

.Sidebar__menuItem {
  cursor: pointer;
  font-size: 1.6rem;
  font-family: "Baguede";

  &__content {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;

    &:hover {
      background: $color-light-gray;
    }

    &__nested {
      display: flex;
      align-items: center;
      padding: 1rem 0 1rem 4rem;
      gap: 1rem;
      &:hover {
        background: $color-light-gray;
      }
    }

    &__wrapper {
      width: 3rem;
      min-width: 3rem;
    }
  }

  span {
    color: $color-black-gray;
    margin-right: auto;
  }
}

.active {
  .Sidebar__menuItem__content--icon--arrow {
    transform: rotate(180deg);
  }

    .Sidebar__menuItem__content__nested.active {
          background: $color-light-gray;
    }

 
}
/* StylesSideBarMenuItem.scss */
