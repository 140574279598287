@import 'src/assets/styles/variables.scss';
@import 'src/assets/styles/fonts.scss';

.formikCheckBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
}

.PurchaseInformation {
  width: 100%;
  display: flex;
  flex-direction: column;


  &__label {
    font-family: 'Baguede';
    font-size: 1.8rem;
    display: block;
    margin-bottom: 3.8rem;
  }

  &__wrapper {
    &--label {
      font-size: 1.6rem;
      font-family: 'Baguede';
      color: $color-black-gray;
      opacity: 0.9;
      display: block;
      margin-bottom: 3rem;
    }

    &--checkbox--group {
      display: flex;
      gap: 4.8rem;
    }
  }
}