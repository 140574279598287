@import 'src/assets/styles/variables.scss';

.ActionListItem {
  gap: 1.7rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background: $color-light-gray;
  }

  &__wrapper--img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 2.4rem;
  }

  &__content--label {
    flex: 1 1 auto;
    white-space: nowrap;
    padding-right: 2rem;
  }
}