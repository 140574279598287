.SideBarMenuUserBottomAction {
  display: flex;
  align-items: center;
  margin-top: 6.5rem;
  font-family: "Baguede";

  &__wrapper--icon {
    margin-right: 2.6rem;
  }

  &__userName {
    font-size: 1.8rem;
    display: block;
    cursor: pointer;
    margin-left: 1rem;
  font-family: "Baguede";
  }
}