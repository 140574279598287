$color-orange: #DD853E;
$color-deep-orange: #db7725;
$color-white: #fff;
$color-black: #000f;
$color-black-gray: #272727;
$color-gray: #f5f4f4;
$color-asphalt: #707070;
$color-light-gray: #E7E6EB;

$base-input-border: 0.5px solid #000000;

:export {
  color-orange: $color-orange;
  color-deep-orange: $color-deep-orange;
  color-white: $color-white;
  color-black: $color-black;
  color-black-gray: $color-black-gray;
  color-gray: $color-gray;
  color-asphalt: $color-asphalt;
  color-light-gray: $color-light-gray;
  base-input-border: $base-input-border;
}