@import 'src/assets/styles/variables.scss';

.DashboardLayout {
  display: flex;
  min-height: 100%;
  padding-left: 27rem;

  &__wrapper--logo {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  &__sidebar {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: $color-gray;
    height: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 25rem;

    ul {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
      li:last-child {
        margin-top: auto;
      }
    }
  }
}