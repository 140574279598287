@import "src/assets/styles/fonts.scss";
@import "src/assets/styles/variables.scss";

.accor-wrap {
  width: 100%;
}

.accordion-item {
  border-bottom: 0.2px solid rgb(227, 226, 226);
}

.noBorderBottom {
  border-bottom: none;
}

.accordion-header {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  padding-bottom: 0;

  .actions {
    display: flex;
    align-items: center;

    .arrow {
      cursor: pointer;
    }
    .status {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
    }
  }
}
