@import 'src/assets/styles/variables.scss';


.customAuto .MuiAutocomplete-listbox{
    font-family: "Baguede";
    border: 5px solid green;
}

.customAuto .MuiAutocomplete-option {
    font-family: "Baguede";
    border: 5px solid green;
}